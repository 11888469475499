@mixin clearfix {
	&::after,
	&::before {
		content: "";
		display: table;
		clear: both;
	}
}

@mixin square($size) {
	height: $size;
	width: $size;
}

@mixin respond($breakPoint) {
	@if $breakPoint == phone {
		@media (max-width: 600px) {
			@content;
		}
	}

	@if $breakPoint == tab-port {
		@media (min-width: 600px) and (max-width: 1024px) {
			// 56.25 em
			@content;
		}
	}

	@if $breakPoint == small-desktop {
		@media (min-width: 1025px) and (max-width: 1279px) {
			@content;
		}
	}

	@if $breakPoint == regular-desktop {
		@media (min-width: 1280px) and (max-width: 1400px) {
			@content;
		}
	}

	@if $breakPoint == large-desktop {
		@media (max-width: 1900px) {
			@content;
		}
	}
}

@mixin hideInMobile {
	@include respond("phone") {
		display: none;
	}
}

@mixin fullWidthInMobile {
	@include respond("phone") {
		width: 100%;
	}
}

@mixin modalOuterWidth {
	max-width: 100vw !important;
	min-width: 100vw;
	min-height: 100vh;
}

@mixin modalInnerWidth {
	width: 100%;
	height: 100vh;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;

	@include respond("phone") {
		height: auto;
	}
}
