/*Date picker scss*/

.daterangepicker_input {
	display: none;
}

.daterangepicker:before {
	opacity: 0;
}

.daterangepicker:after {
	top: 0 !important;
}

.daterangepicker.dropdown-menu {
	box-shadow: $box-shadow;
	border-width: 0;
	border-radius: 0;
}

.daterangepicker .calendar th {
	color: $black-color;
	font-size: 1.8rem;
}

.daterangepicker {
	font-family: $font-family !important;
	padding: 1.5rem 7rem 1rem 7rem !important;
	margin-top: 23px !important;
	margin-bottom: 2rem !important;

	@include respond("phone") {
		padding: 1rem !important;
		width: 90% !important;
	}

	@include respond("tab-port") {
		padding: 1rem !important;
	}

	@include respond("small-desktop") {
		padding: 1rem !important;
	}
}

.daterangepicker .calendar td {
	font-size: 1.8rem;
	min-width: 5rem !important;
	height: 5rem !important;
	border-radius: 50% !important;
}

.daterangepicker .calendar {
	max-width: initial !important;
}

.table-condensed thead tr {
	&:first-child {
		th {
			padding-bottom: 2.7rem !important;
		}
	}

	&:last-child {
		th {
			padding-bottom: 1rem;
		}
	}
}

.active.start-date.available:not(.off) {
	background-color: $primary-color !important;
}

.calendar.right {
	margin-left: 10rem !important;

	@include respond("phone") {
		margin-left: 0 !important;
	}

	@include respond("tab-port") {
		margin-left: 0 !important;
	}

	@include respond("small-desktop") {
		margin-left: 0 !important;
	}
}

.prev,
.next {
	background: $white-color;
	box-shadow: $box-shadow;
	position: absolute;
	height: 5rem !important;
	width: 5rem !important;
	border-radius: 50% !important;
	top: 20rem !important;
	background-size: 7px !important;
	background-position: center !important;
}

.prev {
	background: url("/assets/images/chevronLeft.png") no-repeat $white-color;
	left: -2rem;
}

.next {
	background: url("/assets/images/chevronRight.png") no-repeat $white-color;
	right: -2rem;
}

.daterangepicker td.in-range:not(.off) {
	background: rgba($primary-color, 5%) !important;
}

.daterangepicker td.active.end-date:not(.off) {
	background: $primary-color !important;
	color: $white-color !important;
}

.daterangepicker td.off {
	opacity: 0 !important;
	height: 0 !important;
}

.daterangepicker th.month {
	color: #42526e;
}
