.form-control {
	height: 10rem;
	position: relative;

	&--autocomplete {
		position: relative;

		&:before {
			color: rgba($black-color, 0.4);
			position: absolute;
			content: "";
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid;
			margin: 0 4px;
			right: 10px;
			top: 3rem;
		}
	}

	&--prifix {
		position: relative;

		input,
		mat-icon {
			cursor: pointer;
		}

		mat-icon {
			@include square(2.2rem);
			color: rgba($black-color, 0.3);
			z-index: -1;
		}

		.mat-form-field-suffix {
			position: relative !important;
			top: 0 !important;
			width: 3rem !important;
		}
	}

	mat-error + mat-error {
		display: none;
	}

	mat-icon[matprefix] {
		@include square(1.8rem);
		color: rgba($black-color, 0.4);
		position: relative;
		top: -0.6rem;
		left: 0.7rem;
	}

	mat-error {
		font-size: 1.4rem;
	}

	.mat-form-field-appearance-outline .mat-form-field-outline {
		color: rgba($black-color, 0.2);
	}

	.mat-form-field-label {
		color: rgba($black-color, 0.4);
		font: {
			size: 1.8rem;
			weight: 600;
		}
	}

	.mat-input-element,
	.mat-select {
		color: $black-color;
		font: {
			size: 1.8rem;
			weight: 600;
		}
		position: relative;
		top: -0.4rem;
	}

	.mat-form-field-appearance-outline .mat-form-field-label {
		//top: 3rem;
	}

	.mat-form-field-label-wrapper {
		top: -1.8rem;
	}
}

.form-control-textarea {
	height: 14rem;

	textarea {
		resize: none !important;
	}
}

.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
	width: 4rem;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-start {
	border-radius: 3px;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid.ng-untouched
	.mat-form-field-outline-thick,
.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-form-field.mat-form-field-invalid
	.mat-form-field-label
	.mat-form-field-required-marker,
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid
	.mat-form-field-outline-thick,
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
	color: rgba($black-color, 0.4) !important;
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
	caret-color: rgba($black-color, 0.4) !important;
}

.sign-up-layout {
	.mat-form-field-appearance-outline .mat-form-field-flex {
		padding: 0 0.75em 0 3rem;
	}
}

.mat-select-arrow-wrapper {
	color: rgba($black-color, 0.4);
	position: relative;
	top: 0.4rem;
}
