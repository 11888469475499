$font-family: "Muli", sans-serif;

$primary-color: #00d7bd;
$primary-hover-color: #02e9cd;
$secondary-color: #42526e;
$blue-color: #39a0ed;
$black-color: #1d2c3c;
$white-color: #ffffff;
$grey-color: rgba(#1d2c3c, 0.8);
$grey-color-2: #f8f9fc;
$grey-color-3: #f4f4f4;
$grey-color-4: #e8eaec;
$grey-color-5: #f8f9fc;
$grey-color-6: rgba($black-color, 0.8);
$grey-color-7: #dddddd;
$red-color: #ff5555;

$box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);

$z-index: (
	one: 1,
	two: 2,
	header: 100,
);

$header-height: 7rem;
$mobile-header-height: 7rem;

$search-box-height: 30rem;
$search-box-height-mobile: 7rem;
$search-box-height-tab: 57rem;
