.management-layout {
	@include clearfix();
	margin-top: 5rem;

	&__left {
		float: left;
		width: 30rem;

		h3 {
			font-size: 2.2rem;
			font-weight: 600;
			margin-top: 3rem;
			margin-bottom: 1.8rem;
		}

		div {
			&:first-child {
				button {
					height: 5rem;
				}
			}
		}

		&__list {
			max-height: 65rem;
			overflow-y: auto;
			margin-right: 1rem;

			&--roles {
				max-height: 25rem !important;
			}

			&--view-only {
				max-height: 25rem !important;

				li {
					padding: 1.3rem;
					font-size: 1.8rem;
					cursor: default !important;
					display: block;
					margin-bottom: 0.5rem;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;

					&:hover {
						color: $black-color !important;
						background: $white-color !important;
					}
				}
			}

			ul {
				padding-right: 1rem;

				li {
					position: relative;

					a {
						padding: 1.3rem;
						font-size: 1.8rem;
						cursor: pointer;
						display: block;
						margin-bottom: 0.5rem;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;

						&:hover {
							background: rgba($primary-color, 0.04);
							color: $black-color !important;

							mat-icon {
								display: inline-block;
							}
						}

						&.active {
							background: rgba($primary-color, 0.04);
							color: $primary-color;
							font-weight: 600;

							&:hover {
								background: rgba(
									$primary-color,
									0.04
								) !important;
								color: $primary-color !important;
								font-weight: 600 !important;
							}
						}

						mat-icon {
							@include square(1.8rem);
							position: absolute;
							top: 1.5rem;
							right: 1rem;
							display: none;

							@include respond("phone") {
								display: block;
							}

							&:hover {
								color: $primary-color;
							}
						}
					}
				}
			}
		}
	}

	&__right {
		float: left;
		width: calc(100% - 30rem);

		&__box {
			border: 1px solid $grey-color-4;
			border-radius: 0.8rem;
			padding: 4rem;
			margin-bottom: 3rem;

			&__header {
				.primary-button-color {
					span {
						color: $primary-color;
						font-weight: 500;
						font-size: 2rem;
					}

					.mat-button-focus-overlay {
						background: $primary-color;
					}
				}

				.text-right {
					button {
						height: 5rem;
						min-width: auto !important;

						span {
							margin-right: 0;
						}
					}
				}

				h2 {
					color: $black-color;
					margin-bottom: 1.2rem;
					font-size: 2.6rem;
					font-weight: 600;
				}

				p {
					color: rgba($black-color, 0.8);
					font-size: 1.8rem;
				}

				span {
					display: inline-block;
					font-size: 1.8rem;
					margin-right: 1rem;
					font-weight: 700;
				}

				a {
					color: $primary-color;
					font-size: 1.8rem;
				}
			}

			&__table {
				margin-top: 5rem;

				&__tab {
					border-bottom: 1px solid $grey-color-4;
					margin-bottom: 2rem;
					padding-left: 2rem;

					li {
						display: inline-block;
						position: relative;
						top: 1px;

						a {
							display: inline-block;
							padding: 2rem 3rem;
							font-size: 2rem;
							font-weight: 600;
							border: 1px solid transparent;
							border-bottom-color: $grey-color-4 !important;

							&.active,
							&:hover {
								color: $blue-color !important;
							}

							&.active {
								border-color: $grey-color-4;
								border-bottom-color: $white-color !important;
							}
						}
					}
				}

				table {
					width: 100%;

					tbody {
						tr {
							div {
								word-break: break-word;
								white-space: normal;
								padding-right: 1rem;
								font-size: 1.8rem;
							}
						}
					}
				}
			}
		}
	}
}
