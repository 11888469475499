.form-control-radio {
	margin-bottom: 4rem;

	&__title {
		color: $black-color;
		margin-right: 3rem;
		font-size: 1.8rem;
		font-weight: 600;
		vertical-align: middle;
	}

	.mat-radio-label-content {
		color: rgb($black-color, 0.4);
		font-size: 1.8rem;
	}

	.mat-radio-button {
		margin-right: 4rem;
	}

	.mat-radio-button.mat-radio-checked {
		.mat-radio-label-content {
			color: $black-color;
		}
	}

	.mat-radio-outer-circle {
		border-color: rgb($black-color, 0.4);
	}
}
