.mat-dialog-content {
	max-height: 100vh !important;
	/*overflow: hidden !important;*/

	@include respond("phone") {
		max-height: auto !important;
		padding: 35px !important;
		margin: 0 !important;
	}
}

.dialog-section {
	width: 60rem;
	margin: 0 auto;

	&--large {
		width: 140rem;
	}

	h2 {
		color: $black-color;
		font-size: 3.2rem;
		font-weight: 700;
		margin-bottom: 4rem;

		@include respond("phone") {
			font-size: 2rem;
		}

		@include respond("phone") {
			font-size: 2.2rem;
			margin-bottom: 2rem;
		}

		.mat-icon {
			@include square(3.5rem);
			vertical-align: middle;
		}
	}

	p {
		font-size: 1.8rem;
		font-weight: normal;
		line-height: 1.44;
		text-align: left;
		color: $black-color;
		margin-bottom: 5rem;
	}

	&__logo {
		line-height: $header-height;
		position: fixed;
		left: 6rem;
		top: 0;

		@include respond("phone") {
			display: none;
		}

		img {
			vertical-align: middle;
			width: 13rem;
		}
	}

	&__close {
		a {
			@include square(5.7rem);
			position: absolute;
			text-align: center;
			overflow: hidden;
			display: inline-block;
			top: 1.5rem;
			right: 3rem;
			border-radius: 50%;
			line-height: 6.7rem;

			@include respond("phone") {
				@include square(4rem);
				line-height: 4.5rem;
				right: 0;
				top: 1rem;
			}

			@include respond("tab-port") {
				top: -0.5rem;
				right: 0;
			}

			&:hover {
				background-color: $grey-color-2;

				mat-icon {
					color: $primary-color;
				}
			}

			.mat-icon {
				@include square(2.8rem);

				@include respond("phone") {
					@include square(1.4rem);
				}
			}
		}
	}

	.mat-stroked-button.mat-primary {
		margin-bottom: 1rem;
	}
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
	opacity: 0 !important;
}
