.table {
	display: table;
	border-collapse: collapse;
	border-spacing: 0;

	thead {
		border-top: 1px solid $grey-color-4;
		border-bottom: 1px solid $grey-color-4;
		text-align: left;

		/*&:after {
      position: absolute;
      height: calc(7rem - 2px);
      border-top: 1px solid $grey-color-4;
      border-bottom: 1px solid $grey-color-4;
      content: '';
      top: 2.5rem;
      width: 100%;
    }*/

		tr {
			&:first-child {
				th {
					color: rgba($black-color, 0.7);
					font-size: 1.7rem;
					height: 5rem;
					position: relative;

					&:first-child {
						padding-left: 1rem;
					}

					a {
						display: block;

						mat-icon {
							width: 1.6rem;
							height: 1.3rem;
							margin-left: 1rem;
						}
					}
				}
			}

			&:last-child {
				th {
					&:first-child {
						padding-left: 1rem;
					}

					> div {
						width: 85%;
					}
				}

				.mat-form-field-appearance-outline:not(.mat-form-field-disabled)
					.mat-form-field-flex:hover
					.mat-form-field-outline-thick {
					color: $grey-color-4;
				}

				.mat-form-field-infix {
					border-top: 0;
				}

				.mat-form-field-appearance-outline .mat-form-field-infix {
					padding: 1em 0 0.8em 0;
				}

				input,
				.mat-select-placeholder {
					font-weight: 600;
				}

				.mat-select-arrow {
					position: relative;
					top: 0.2rem;
				}
			}
		}
	}

	tbody {
		text-align: left;

		.clickable-row {
			background: $grey-color-2;
		}

		tr {
			border-bottom: 1px solid $grey-color-4;
			position: relative;

			.user-name-link-placeholder {
				mat-icon {
					@include square(3rem);
					margin-right: 1rem;
					vertical-align: middle;
				}

				+ a {
					vertical-align: middle;
				}
			}

			&:hover {
				background: $grey-color-2;

				.user-name-link {
					color: $primary-color;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			td {
				color: $black-color;
				font-size: 1.8rem;
				font-weight: 600;
				height: 5rem;

				&:first-child {
					padding-left: 1rem;
				}

				div {
					/*white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;*/
				}
			}

			&:last-child {
				border-bottom-width: 0;
			}
		}
	}
}

.hover-action {
	background: $grey-color-2;
	position: absolute;
	padding: 0 !important;
	width: 180px;

	button {
		position: relative;
		top: 0.45rem;
		height: 4rem !important;
		padding: 0 !important;
		text-align: center;
		line-height: 4rem;
		width: 80px !important;
		min-width: 80px !important;

		span {
			color: $primary-color;
			font-size: 1.8rem;
			font-weight: 600;
		}

		.mat-button-focus-overlay {
			background: $primary-color;
		}
	}

	a {
		display: inline-block;
		margin-right: 4rem;

		mat-icon {
			height: 6rem;
			width: 2rem;
			line-height: 6rem;
			vertical-align: middle;
		}
	}
}
