// ---------------------------------------------------------------------------------------------
// ===============================  Class for Margin and Padding  ==============================
// ---------------------------------------------------------------------------------------------
$directions: (top, left, right, bottom);
$spaceList: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
@each $space in $spaceList {
	@each $direction in $directions {
		.M#{to_upper_case(str_slice($direction,0,1))}-#{$space} {
			margin-#{$direction}: #{$space}rem !important;
		}

		.P#{to_upper_case(str_slice($direction,0,1))}-#{$space} {
			padding-#{$direction}: #{$space}rem !important;
		}
	}
}

.PL-0 {
	padding-left: 0;
}

.PR-0 {
	padding-right: 0;
}

.PL-1 {
	padding-left: 1rem !important;
}

@include respond("phone") {
	.xs-PR-0 {
		padding-right: 0 !important;
	}

	.xs-PL-0 {
		padding-left: 0 !important;
	}

	.xs-PB-4 {
		padding-bottom: 4rem !important;
	}

	.xs-PLR0 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

@include respond("tab-port") {
	.sm-PR-0 {
		padding-right: 0 !important;
	}

	.sm-PL-0 {
		padding-left: 0 !important;
	}
}

.row {
	.PL-0 {
		@include respond("phone") {
			padding-right: 0 !important;
		}
	}

	.PR-0 {
		@include respond("phone") {
			padding-left: 0 !important;
		}
	}
}
