.report-container {
	padding-bottom: 4rem;
	margin-top: 3.5rem;

	h4 {
		font-weight: bold;
		font-size: 2.6rem;
	}

	a {
		color: $blue-color;
		font-size: 1.6rem;
		font-weight: 600;

		mat-icon {
			width: 1.6rem;
			height: 1.4rem;
			margin-left: 1rem;
		}
	}

	.pagination-pdf {
		margin-top: 4rem;

		.pdf-download {
			margin-top: 1rem;
			a {
				color: $primary-color;
				font-weight: normal;
				font-size: 1.8rem;
				margin-right: 4rem;

				&:hover {
					color: $blue-color !important;
				}

				mat-icon {
					@include square(1.4rem);
					margin-right: 1rem;
				}
			}
		}
	}

	.table {
		width: 100%;
		margin-top: 3rem;
		white-space: nowrap !important; /* Prevent line breaks in table cells */

	}
}

.filter-container {
	.report-form-field {
		@include clearfix();
		margin-top: 4rem;

		&__control {
			// float: left;
			// width: 20%;
			padding: 0 15px;

			.form-control {
				// height: 8rem !important;
			}

			a {
				display: inline-block;

				mat-icon[svgIcon="cross"] {
					@include square(1.2rem);
					position: relative;
					top: -7px;
					right: 0;
				}
			}

			.mat-datepicker-toggle-default-icon {
				position: relative;
				top: 0rem;
				right: 1rem;
				height: 2rem !important;
				width: 2rem !important;
			}
		}
	}

	a {
		color: $blue-color;
		text-decoration: underline;
		display: block;
		font-weight: 600;
		font-size: 1.6rem;
		margin-top: 1rem;
	}
}

.employee-trip-srollbar {
	overflow-x: auto;

	table {
		width: 2500px !important;
	}
}

.flight-charge-master-srollbar {
	overflow-x: auto;

	table {
		width: 4500px !important;
	}
}

.flight-master-srollbar {
	overflow-x: auto;

	table {
		width: 4000px !important;
	}
}

.download-grid {
	mat-icon {
		margin-left: 0 !important;
		margin-right: 1rem !important;
	}
}

.trip-amount {
	width: 5% !important;
	padding-right: 0 !important;
}

.amount {
	width: 15% !important;
}

.merge-table {
	.table {
		thead,
		tbody {
			tr {
				th,
				td {
					border: 1px solid $grey-color-7;
					padding-left: 1rem;
					padding-right: 1rem;
					white-space: normal;
					// word-break: break-word;
				}
			}
		}
	}
}
