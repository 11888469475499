@import "node_modules/@angular/material/theming";

@include mat-core();

$mat-test: (
	50: #e8eaf6,
	100: #c5cae9,
	200: #9fa8da,
	300: #7986cb,
	400: #5c6bc0,
	500: #00d7bd,
	600: #3949ab,
	700: #303f9f,
	800: #283593,
	900: #1a237e,
	A100: #8c9eff,
	A200: #536dfe,
	A400: #3d5afe,
	A700: #304ffe,
	contrast: (
		50: $dark-primary-text,
		100: $dark-primary-text,
		200: $dark-primary-text,
		300: $light-primary-text,
		400: $light-primary-text,
		500: $light-primary-text,
		600: $light-primary-text,
		700: $light-primary-text,
		800: $light-primary-text,
		900: $light-primary-text,
		A100: $dark-primary-text,
		A200: $light-primary-text,
		A400: $light-primary-text,
		A700: $light-primary-text,
	),
);

$mat-red-test: (
	50: #ffebee,
	100: #ffcdd2,
	200: #ef9a9a,
	300: #e57373,
	400: #ef5350,
	500: #ff6e6e,
	600: #e53935,
	700: #d32f2f,
	800: #c62828,
	900: #b71c1c,
	A100: #ff8a80,
	A200: #ff5252,
	A400: #ff1744,
	A700: #d50000,
	contrast: (
		50: $dark-primary-text,
		100: $dark-primary-text,
		200: $dark-primary-text,
		300: $dark-primary-text,
		400: $dark-primary-text,
		500: $light-primary-text,
		600: $light-primary-text,
		700: $light-primary-text,
		800: $light-primary-text,
		900: $light-primary-text,
		A100: $dark-primary-text,
		A200: $light-primary-text,
		A400: $light-primary-text,
		A700: $light-primary-text,
	),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat-palette($mat-test);
$candy-app-accent: mat-palette($candy-app-primary, 500);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat-palette($mat-red-test);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat-light-theme(
	$candy-app-primary,
	$candy-app-accent,
	$candy-app-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);

$custom-typography: mat-typography-config(
	$font-family: unquote("Muli, sans-serif"),
);

@include angular-material-typography($custom-typography);

.mat-form-field {
	width: 100%;
	font-size: 1.6rem;
}

/*LOGIN FLOW & FORGOT PASSWORD FLOW SCSS*/
.app-section {
	.mat-horizontal-content-container {
		@include respond("phone") {
			padding: 0;
		}

		@include respond("tab-port") {
			padding: 0;
		}
	}

	.mat-checkbox-background,
	.mat-checkbox-frame,
	.mat-checkbox-inner-container {
		@include square(2.6rem);
		border-radius: 50%;

		@include respond("phone") {
			margin-top: 0.15rem;
		}
	}

	.mat-checkbox-label {
		font-size: 1.8rem;
		position: relative;
		top: 0.1rem;

		@include respond("phone") {
			top: -0.3rem !important;
		}
	}

	.mat-checkbox-inner-container {
		margin-right: 2rem;

		@include respond("phone") {
			margin-right: 1rem;
		}
	}

	.mat-checkbox-background {
		svg {
			@include square(2rem);
			margin: 0 auto;
			top: 0.3rem;
		}
	}
}

/*SIGN UP FLOW SCSS*/
.sign-up-container {
	&__row {
		&__left {
			.mat-horizontal-content-container {
				@include respond("phone") {
					padding: 0;
				}
			}
		}
	}
}

/*USER AUTH FLOW*/
.app-section,
.sign-up-container {
	.mat-horizontal-stepper-header-container {
		display: none !important;
	}
}

/*IN WHOLE APPLICATION*/
* {
	.mat-menu-panel {
		min-height: auto !important;
	}

	.mat-dialog-container {
		padding: 0 !important;
	}

	.mat-checkbox-frame {
		border-color: rgba($black-color, 0.4);
	}

	.mat-button.cdk-keyboard-focused .mat-button-focus-overlay,
	.mat-button.cdk-program-focused .mat-button-focus-overlay,
	.mat-flat-button.cdk-keyboard-focused .mat-button-focus-overlay,
	.mat-flat-button.cdk-program-focused .mat-button-focus-overlay,
	.mat-stroked-button.cdk-keyboard-focused .mat-button-focus-overlay,
	.mat-stroked-button.cdk-program-focused .mat-button-focus-overlay {
		opacity: 0 !important;
	}

	.mat-form-field-appearance-outline .mat-form-field-outline-start {
		border-radius: 3px 0 0 3px !important;
	}

	.mat-form-field-appearance-outline .mat-form-field-outline-end {
		border-radius: 0 3px 3px 0 !important;
	}

	.mat-form-field-appearance-outline
		.mat-form-field-outline-thick
		.mat-form-field-outline-end,
	.mat-form-field-appearance-outline
		.mat-form-field-outline-thick
		.mat-form-field-outline-gap,
	.mat-form-field-appearance-outline
		.mat-form-field-outline-thick
		.mat-form-field-outline-start {
		border-width: 1px !important;
	}

	/*.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
    background: $primary-hover-color;
  }*/

	.mat-option.mat-selected {
		background: rgba($primary-color, 14%) !important;
		color: $primary-color !important;
	}

	.mat-option-text {
		color: rgba($black-color, 0.8) !important;
		font-size: 1.8rem !important;
		font-weight: 600 !important;
	}

	.mat-option:hover:not(.mat-option-disabled),
	.mat-option:focus:not(.mat-option-disabled) {
		background: $grey-color-2;
	}

	.mat-slide-toggle-thumb-container {
		cursor: pointer !important;
	}

	.mat-option.mat-selected {
		.mat-option-text {
			color: $primary-color !important;
		}
	}

	.search-plane-icon {
		mat-icon {
			@include square(1.6rem);
			color: rgba($grey-color, 0.5);
			transform: rotate(90deg);
		}
	}

	.location-name {
		color: $black-color;
		font-weight: 600;
	}

	.white-snackbar {
		background: $white-color;
		color: $black-color;
		font-weight: 600;
	}
}

/*HOTEL SEARCH AND FLIGHT SEARCH SCSS*/
.search-box {
	&__dropdown {
		.mat-focused {
			.mat-form-field-infix {
				background: $grey-color-2 !important;
			}
		}

		.mat-form-field-appearance-legacy .mat-form-field-underline {
			height: 0 !important;
		}

		.mat-form-field-infix {
			background: transparent;
			border-top: 0;
			height: 4.6rem;

			&:hover {
				background: $grey-color-2;
			}

			mat-label,
			.mat-select-value-text span {
				font-size: 1.8rem;
				line-height: 3rem;
				padding-left: 1rem;
			}
		}

		.mat-select-arrow,
		.mat-form-field-underline {
			opacity: 0;
		}
	}
}

.employee-list-header__pagination {
	&__dropdown {
		button {
			color: rgba($black-color, 0.8);
			position: relative;
			display: block;
			padding-left: 5rem;
			font-size: 1.8rem;
			height: 5rem;
			line-height: 5rem;

			&:hover {
				color: $primary-color !important;
				background: rgba($primary-color, 0.18) !important;

				mat-icon {
					color: $primary-color !important;
				}
			}

			&.disable {
				color: rgba($black-color, 0.5);

				&:hover {
					background: transparent !important;
					color: rgba($black-color, 0.5) !important;

					mat-icon {
						color: rgba($black-color, 0.5) !important;
					}
				}
			}

			mat-checkbox.mat-checkbox {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				z-index: 4;
				height: 100%;
				opacity: 0;
			}

			.mat-checkbox-layout {
				height: 100%;
				width: 100%;
			}

			mat-icon {
				@include square(1.5rem);
				position: absolute;
				left: 1.5rem;
				top: 1.7rem;
			}
		}
	}
}

.create-grade-dialog-container,
.create-department-dialog-container,
.create-roles-dialog-container {
	@include modalOuterWidth;

	app-grade-details,
	app-department-details,
	app-roles-and-privilege-details {
		@include modalInnerWidth;
	}
}
.mat-error + .mat-error {
	display: none;
}

.edit-profile-details-dialog-container {
	@include modalOuterWidth;

	app-employee-organization-profile-details-edit {
		@include modalInnerWidth;
	}
}

.confirm-message-container {
	.yes-btn {
		span {
			color: $primary-color;
		}

		.mat-button-focus-overlay {
			background: $primary-color !important;
		}
	}

	.no-btn {
		span {
			color: $red-color;
		}

		.mat-button-focus-overlay {
			background: $red-color !important;
		}
	}

	button {
		span {
			font-size: 1.8rem;
			font-weight: 600;
		}
	}
}

.report-btn-header {
	mat-icon {
		display: none;

		@include respond("phone") {
			display: block;
			margin: 3px auto 0px auto;
		}
	}

	.mat-button-wrapper {
		color: $grey-color-6 !important;
		font-size: 1.7rem !important;
	}

	.mat-button-ripple.mat-ripple,
	.mat-button-focus-overlay {
		display: none;
	}

	&:hover {
		.mat-button-wrapper {
			color: $primary-color !important;
		}
	}
}

.report-dropdown {
	.mat-menu-item {
		height: 4.4rem;
		line-height: 4.4rem;
		font-size: 1.6rem;
	}
}
