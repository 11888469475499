// --------------------------------------------------------------------------------------
// =============================== Base scss file for project ===========================
// --------------------------------------------------------------------------------------

/** 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
	-webkit-text-size-adjust: 100%; /* 2 */
	font-size: 10px;

	@include respond("regular-desktop") {
		font-size: 7px;
	}

	@include respond("phone") {
		font-size: 7px;
	}

	@include respond("large-desktop") {
		font-size: 8px;
	}
}

/* Sections
   ========================================================================== */

/**
 * Remove the margin in all browsers.
 */

body {
	margin: 0;
	font: {
		size: 1.4rem;
		weight: 500;
		family: $font-family;
	}
}

::-webkit-scrollbar {
	width: 0.5rem;
	height: 0.5rem;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: #bbb;
	border-radius: 5px;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

a {
	cursor: pointer;

	&:hover {
		color: $primary-color !important;
	}
}

.primary-link {
	color: $primary-color;

	&:hover {
		color: $primary-hover-color !important;
	}
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.primary-color {
	color: $primary-color;
}

.red-color {
	color: $red-color !important;
}

.app-section {
	@include clearfix();

	&__left {
		@include hideInMobile;
		position: fixed;
		left: 0;
		height: 100%;
		width: 50%;

		@include respond("tab-port") {
			display: none;
		}

		&__one {
			&__small-img {
				width: 15rem;
				position: fixed;
				left: 4rem;
				top: 4rem;
				z-index: 1;
			}

			h3 {
				color: $white-color;
				font-size: 3.2rem;
				margin-bottom: 2rem;
				font-weight: 600;
			}

			p {
				color: rgba($white-color, 0.8);
				font-size: 2rem;
				font-weight: 500;
				line-height: 3rem;
				margin: 0 auto;
				position: relative;
			}
		}
	}

	&__right {
		left: calc(50% + 17rem);
		// position: relative;
		// width: calc(100% - calc(50% + 17rem));
		display: flex;
		align-items: center;
		height: 100vh;
		justify-content: center;

		@include respond("phone") {
			left: 0;
			width: 90%;
			margin: 0 auto;
		}

		@include respond("tab-port") {
			left: auto;
			width: 80%;
			margin: 0 auto;
		}

		@include respond("small-desktop") {
			left: calc(50% + 6rem);
			width: calc(100% - calc(50% + 9rem));
		}

		@include respond("regular-desktop") {
			left: calc(50% + 10rem);
			width: calc(100% - calc(50% + 13rem));
		}

		&__container {
			width: 58rem;

			h2 {
				color: $black-color;
				font-size: 3.6rem;
				margin-bottom: 2rem;

				/*@include respond('phone'){
          font-size: 2rem;
        }*/
			}

			p {
				color: $grey-color;
				font-size: 2rem;
				margin-bottom: 4.9rem;

				/*@include respond('phone'){
          font-size: 1.4rem;
          margin-bottom: 2rem;
        }*/
			}
		}
	}
}

.width-80 {
	width: 80%;
}

.swiper-pagination-bullet {
	@include square(1.6rem);
	border-radius: 50%;
	background: #e6e6e6;
	border: 0.2rem solid #e6e6e6;
	opacity: 1;
	display: block;
	margin: 0 auto;
	cursor: pointer;

	&:focus {
		outline: none;
	}
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background: $primary-color;
	border: 0.2rem solid #e6e6e6;
}

.swiper-pagination-bullet[aria-label="Go to slide 2"] {
	margin: 4rem 0;
}

.sign-up-layout {
	left: 25rem;
	width: 58rem;
	position: relative;

	@include respond("phone") {
		left: 0;
		width: 100%;
	}

	@include respond("tab-port") {
		width: 80%;
		left: 10%;
	}

	@include respond("small-desktop") {
		left: 5rem;
	}

	@include respond("regular-desktop") {
		left: 18rem;
	}

	h2 {
		font-size: 3.2rem;
		margin-bottom: 2.2rem;

		@include respond("phone") {
			font-size: 2.8rem;
		}

		mat-icon {
			@include square(3.4rem);
			margin-right: 1.6rem;

			@include respond("phone") {
				@include square(3rem);
				margin-right: 0.6rem;
				vertical-align: middle;
			}
		}
	}

	p {
		color: rgb($black-color, 0.8);
		font-size: 1.8rem;
		margin-bottom: 4rem;
	}
}

.mat-ripple-element {
	@include hideInMobile;
}

.password-show-hide-icon {
	mat-icon {
		@include square(2rem);
		color: #778088;
	}
}

.panel {
	top: $header-height;
	position: relative;
	padding: 0 calc(6rem - 15px);
	margin-top: 1rem;

	@include respond("phone") {
		padding: 0 1.5rem;
		margin-top: 0;
	}

	h3 {
		color: $black-color;
		font-size: 2.6rem;
		margin-top: 3.5rem;

		@include respond("phone") {
			font-size: 1.8rem;
			margin-top: 1.5rem;
		}
	}
}

.filter-field {
	.mat-form-field-appearance-outline .mat-form-field-suffix {
		width: 2rem;
	}

	.mat-form-field-infix {
		width: auto !important;
	}

	button {
		min-width: auto !important;
		height: 3rem !important;
		width: 3rem !important;
		margin: 0 !important;
		position: relative;
		top: 0.4rem;
		right: 0.5rem;

		mat-icon[svgIcon="cross"] {
			@include square(1.4rem);
		}
	}
}

.filter-row {
	background: $white-color;
	top: $header-height;
	position: sticky;
	z-index: 5;
	padding: 1.5rem 0;

	@include respond("phone") {
		position: initial;
	}

	@include respond("tab-port") {
		position: initial;
	}
}

.hide-in-mobile {
	@include hideInMobile();
	display: block;
}

.hide-in-web {
	display: none !important;

	@include respond("phone") {
		display: inline-block !important;
	}
}

.mobile-bottom-menu-space {
	@include respond("phone") {
		padding-bottom: 10rem !important;
	}
}

.text-decoration-none {
	text-decoration: none;
}

.filter-container {
	.submit-btn {
		border: 1px solid $primary-hover-color !important;
		height: 4.6rem !important;
		min-width: 12rem !important;
		width: 12rem !important;
		border-radius: 3px;
	}
}
