// View Profile info scss
.profile-view-container {
	.mat-tab-labels {
		justify-content: center;

		.mat-tab-label {
			opacity: 1;
		}

		.mat-tab-label-active {
			.mat-tab-label-content {
				color: $primary-color;
			}
		}

		.mat-tab-label-content {
			font-size: 2rem;
			font-weight: 600;
			line-height: 1.8;
			text-align: center;
			color: $black-color;
		}
	}
}

.document-menu {
	.mat-menu-content:not(:empty) {
		padding: 0;
	}

	.mat-menu-item {
		line-height: 3.5rem;
		height: 3.5rem;
	}
}

.profile-view-container {
	.mat-tab-body-content {
		overflow: hidden;
	}
}

.emp-admin-profile-container {
	margin-top: 3.5rem;

	@include respond("phone") {
		padding: 0 1.5rem;
		margin-top: 2rem;
	}

	.profile-info {
		display: flex;
		justify-content: flex-start;
		flex-direction: row;

		&__image {
			width: 23rem;
			min-width: 23rem;

			@include respond("phone") {
				@include square(10rem);
				min-width: 10rem;
			}

			.profile-image {
				@include square(22.2rem);
				border-radius: 50%;
				position: relative;

				@include respond("phone") {
					@include square(10rem);
				}

				img,
				.mat-icon.user-placeholder-icon {
					@include square(22.2rem);
					border: solid 0.2rem rgba(18, 33, 63, 0.1);
					border-radius: 50%;

					@include respond("phone") {
						@include square(10rem);
					}
				}

				&:hover > .file-upload-layer {
					display: flex;
				}

				.file-upload-layer {
					background: rgba($black-color, 0.4);
					@include square(22.2rem);
					border-radius: 50%;
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
					overflow: hidden;
					display: none;

					@include respond("phone") {
						@include square(10rem);
					}

					a {
						display: inline-block;

						.mat-icon {
							@include square(2rem);
						}
					}

					.upload-image-section {
						height: 100%;
						width: 100%;
						text-align: center;
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;

						&__add {
							mat-icon {
								@include square(4rem);
								color: $white-color !important;
							}
						}

						.mat-icon {
							@include square(3rem);
							color: $white-color;
						}
					}

					.delete-image-section {
						background: $black-color;
						height: 6rem;
						width: 100%;
						text-align: center;
						display: flex;
						justify-content: center;
						align-items: center;

						.mat-icon {
							text-align: center;
							color: $white-color;
						}
					}
				}
			}

			.profile-lable {
				text-align: center;
				margin-top: 1rem;
			}
		}

		&__content {
			margin-left: 5rem;
			// width: calc(100vw - 23rem);
			flex-grow: 1;
			overflow: auto;

			@include respond("phone") {
				margin-left: 2rem;
				width: calc(100vw - 12rem);
			}

			// edit user profile icon scss
			.edit-profile-icon {
				text-align: right;
				margin-bottom: 1rem;

				@include respond("phone") {
					margin-bottom: 0;
					margin-right: 2.5rem;
				}

				a {
					display: inline-block;

					mat-icon {
						color: rgba($black-color, 0.6);
					}

					&:hover {
						mat-icon {
							color: $primary-color !important;
						}
					}

					.mat-icon {
						@include square(2.9rem);

						@include respond("phone") {
							@include square(2.2rem);
						}
					}
				}
			}

			// User personal information scss
			&--personal-info {
				border-bottom: 1px solid $grey-color-4;
				padding-bottom: 6rem;

				@include respond("phone") {
					border-bottom: 0;
					padding-bottom: 0;
				}

				h2 {
					color: $black-color;
					font-weight: bold;
					text-transform: capitalize;
					font-size: 4rem;
					line-height: 1.25;

					@include respond("phone") {
						font-size: 2.5rem;
						margin-top: -1.8rem;
					}
				}

				.user-email-mobile {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					width: 70rem;
					margin-top: 4rem;

					@include respond("phone") {
						margin-top: 1rem;
					}

					div {
						font-size: 2.2rem;
						line-height: 1.27;
						text-align: left;
						color: $black-color;

						@include respond("phone") {
							font-size: 1.6rem;
						}
					}
				}
			}

			// User other information scss
			&--other-info {
				border-bottom: 1px solid $grey-color-4;
				padding: 0 0 3rem 0;

				.row {
					.col-md-3 {
						padding-left: 0;
					}

					.col-md-9.col-xs-7 {
						@include respond("phone") {
							padding-left: 0 !important;
							padding-right: 0 !important;
						}
					}

					label,
					span {
						padding: 1.5rem 0;
						display: inline-block;
						font-size: 2rem;
						line-height: 1.25;
						color: $black-color;
					}

					label {
						font-weight: 600;
						opacity: 0.6;
					}

					a {
						color: $primary-color;

						&:hover {
							color: $primary-color;
						}
					}
				}
			}

			// User documents scss
			&--documents {
				padding: 4rem 0;

				@include respond("phone") {
					padding: 3rem 0;
				}

				.row {
					.col-md-4 {
						&:nth-child(2) {
							padding: 0;
						}
					}
				}

				@at-root h4 {
					font-size: 2.4rem;
					font-weight: bold;
					line-height: 1.5;
					color: $black-color;

					@include respond("phone") {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
					}
				}

				.card-section {
					min-height: 26rem;
					border: 1px solid rgba($black-color, 0.1);
					box-shadow: 0 1px 0.4rem rgba($black-color, 0.1);
					background-color: $white-color;
					padding: 2rem;
					margin-top: 4rem;

					@include respond("phone") {
						margin-top: 2rem;
					}

					&:hover {
						.card-section__content__upload {
							color: $primary-color;
						}
					}

					&--details-added {
						min-height: 32rem !important;
					}

					&__header {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 1rem;

						h5 {
							font-size: 2rem;
							font-weight: 600;
							line-height: 1.8;
							color: $black-color;
							width: calc(100% - 2rem);
						}

						.mat-icon {
							@include square(2rem);
							color: $black-color;
							cursor: pointer;

							&:hover {
								color: $primary-color;
							}
						}
					}

					&__content {
						&__upload {
							font-size: 1.6rem;
							color: rgba($black-color, 0.6);

							&:hover {
								text-decoration: underline;
							}
						}

						&__document {
							@include clearfix();
							margin-bottom: 3rem;

							&__view {
								background: $grey-color-2;
								float: left;
								width: calc(50% - 1rem);
								padding: 3rem 1rem;
								text-align: center;
								margin-right: 1rem;
								position: relative;

								@include respond("phone") {
									padding: 4rem 1rem;
								}

								&__name {
									background: $grey-color-2;
									position: absolute;
									left: 0;
									bottom: 0;
									width: 100%;
									padding: 0.4rem;
								}

								&:last-child {
									margin-right: 0;
								}

								.mat-icon {
									width: 6rem;
									height: 5rem;
								}
							}
						}

						.document-details {
							display: flex;
							flex-direction: column;
							margin-top: 0.5rem;

							div {
								@include clearfix();

								label {
									color: rgba($black-color, 0.6);
									float: left;
									width: 10rem;
									font-size: 1.6rem;
									font-weight: 600;
									padding: 0.5rem 0;
								}

								span {
									color: $black-color;
									float: left;
									width: calc(100% - 10rem);
									font-size: 1.6rem;
									padding: 0.5rem 0;
									white-space: nowrap;
									overflow: hidden;
									text-overflow: ellipsis;
								}
							}
						}
					}
				}
			}

			//Privacy scss
			&--privacy {
				padding: 3rem 0 10rem 0;

				.change-password-link {
					color: $blue-color;
					font-weight: normal;
					float: right;
					font-size: 1.8rem;
					line-height: 1.28;

					@include respond("phone") {
						margin-left: 0;
					}

					&:hover {
						color: $blue-color;
					}
				}

				p {
					margin-top: 1rem;
					font-size: 2rem;
					font-weight: normal;
					line-height: 1.25;
					color: $black-color;
				}
			}
		}
	}
}

.profile-other-info {
	.profile-info__image {
		@include respond("phone") {
			display: none;
		}
	}

	.profile-info__content {
		@include respond("phone") {
			width: 100%;
			margin: 2rem 0 0 0;
		}

		&--other-info {
			@include respond("phone") {
				border-bottom: 1px solid #e8eaec;
				padding: 2rem 2rem 2rem 0;
				border-top: 1px solid #e8eaec;
				margin-top: 2.5rem;
			}
		}
	}
}
