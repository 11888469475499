.mat-flat-button.mat-warn {
	background: $red-color;
	min-width: 17rem;
	height: 4.6rem;
	font-size: 1.8rem;
	border-radius: 0.3rem;

	span {
		color: $white-color !important;
		padding: 0 !important;
		font-size: 1.8rem !important;
	}
}

.mat-flat-button.mat-primary {
	color: $white-color;
	min-width: 17rem;
	height: 6.4rem;
	font-size: 2rem;
	border-radius: 0.3rem;

	@include respond("phone") {
		min-width: 14rem;
		height: 5rem;
	}

	&:hover {
		background: $primary-hover-color;
		box-shadow: $box-shadow;
	}

	&:focus {
		background: $primary-hover-color;
		box-shadow: $box-shadow;
	}

	/*@include respond('phone'){
    width: 18rem;
    height: 5rem;
    font-size: 1.6rem;
  }*/
}

.mat-stroked-button.mat-primary {
	min-width: 12rem;
	height: 6.4rem;
	font-size: 2rem;
	border-radius: 0.3rem;

	@include respond("phone") {
		height: 5rem;
	}

	.mat-button-wrapper {
		color: rgba($black-color, 0.4);
	}

	.mat-button-ripple.mat-ripple {
		display: none;
	}

	&:focus {
		box-shadow: $box-shadow;

		span {
			color: $black-color;
		}

		.mat-button-focus-overlay {
			opacity: 0;
		}
	}

	&:hover {
		border-color: $primary-hover-color;

		span {
			color: $black-color;
		}

		.mat-button-focus-overlay {
			opacity: 0;
		}
	}
}

.header {
	.mat-stroked-button.mat-primary {
		width: 14.5rem;
		height: 5.4rem;
		border-radius: 5rem;

		.mat-button-wrapper {
			color: $primary-color !important;
			font-weight: 600;
		}
	}
}

.search-box {
	&__btn {
		@include respond("phone") {
			margin-bottom: 3rem;
		}

		@include respond("tab-port") {
			margin-bottom: 2rem;
		}

		button {
			margin-right: 1rem;
			border-radius: 3rem;
			height: 5rem;
			padding: 0 2rem;
			border-width: 2px;
			border-color: transparent !important;
			box-shadow: none !important;

			span {
				color: rgba($black-color, 0.6);
				font-weight: 700 !important;
				font-size: 1.47rem;
			}

			&.active {
				border-color: $primary-color !important;

				span {
					color: $black-color;
				}
			}
		}
	}
}

.btn-rounded {
	border-radius: 5rem !important;
	height: 5.5rem !important;
	width: 17rem !important;
}
